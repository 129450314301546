<script lang="ts" setup>
import { useSystemColor } from '@/composables/useSystemColor'
import { OverheadLineId } from '@/model'
import { useCalculationStore } from '@/stores/calculation'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { CalculationLogMessage, LogLevel } from '@gridside/hsb-api'
import { computed, nextTick, ref, watch } from 'vue'

const store = useCalculationStore()
const logEl = ref<HTMLDivElement | undefined>(undefined)

const currentCalculation = computed(() => store.current)
const log = computed<CalculationLogMessage[]>(() => {
  if (store.starting && currentCalculation.value) {
    return [
      {
        calculation: currentCalculation.value?.id,
        data: 'Berechnung wird gestartet...',
        project: currentCalculation.value?.project,
        level: 'INFO'
      }
    ]
  } else {
    return currentCalculation.value ? store.logs[currentCalculation.value.id] : []
  }
})
const logLength = computed(() => log.value?.length || 0)

const scrollToEnd = () => {
  logEl.value?.scrollTo({ top: logEl.value?.scrollHeight + 1000 })
}

watch(logLength, () => {
  nextTick(scrollToEnd)
})

watch(logEl, () => {
  nextTick(scrollToEnd)
})

const overheadLineStore = useOverheadLineStore()
overheadLineStore.ensureLoaded()
const formatOverheadLine = (id: OverheadLineId) => {
  return overheadLineStore.findById(id)?.overheadLine.name || id
}

const operationStates = computed(() => currentCalculation.value?.studyCase?.operationStates || [])
const operationStateColors = computed(() => {
  return operationStates.value.map((operationState) => {
    const systemId = operationState.system
    return systemId ? useSystemColor(systemId) : undefined
  })
})

const colors: Record<LogLevel, string> = {
  [LogLevel.FATAL]: '#ffdddd',
  [LogLevel.CRITICAL]: '#ffdddd',
  [LogLevel.ERROR]: '#ffcccc',
  [LogLevel.WARNING]: '#ffeecc',
  [LogLevel.WARN]: '#ffeecc',
  [LogLevel.INFO]: '#f6f6ff',
  [LogLevel.DEBUG]: 'transparent',
  [LogLevel.NOTSET]: 'transparent'
}
</script>

<template>
  <div
    ref="logEl"
    class="text-xs overflow-y-auto flex-1 font-mono leading-none text-gray-500 bg-white p-2 !pl-8"
  >
    <div v-if="logLength === 0">
      Die Berechnung wurde noch nicht ausgeführt oder die Projektdaten haben sich geändert.
      <br />
      <br />
      Starten Sie die Berechnung mit "Berechnung starten".
    </div>
    <div
      v-for="(logItem, i) in log"
      :key="i"
      :style="`background-color: ${colors[logItem.level] || 'transparent'}`"
      style="margin-bottom: 2px"
      class="flex"
    >
      <div class="whitespace-nowrap">
        <span
          v-if="logItem.operationState !== undefined"
          class="text-white py-px my-px px-1 inline-block rounded"
          :style="`background-color: ${operationStateColors[logItem.operationState]}`"
        >
          {{ logItem.operationState + 1 }}
        </span>
        <span v-if="logItem.overheadLine" class="opacity-60">
          [{{ formatOverheadLine(logItem.overheadLine) }}]
        </span>

        [{{ logItem.level }}]&nbsp;
      </div>
      <div class="flex-1">
        {{ logItem.data.replaceAll('\r', '\n') }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
