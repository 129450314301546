<script lang="ts" setup>
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'
import { useProject } from '@/composables/useProject'

import { useCorridorStore } from '@/stores/corridor'
import { Feature } from 'ol'
import { GeoJSON } from 'ol/format'
import { Vector as VectorSource } from 'ol/source'
import { computed, inject, ref, watch } from 'vue'
import { corridorStyle } from './corridorStyle'

const { view } = inject(UseMapInjectKeys.useMap) as UseMapItems

const source = ref<VectorSource>()
const { project } = useProject()

const { layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
const visibleOverheadLines = computed(() => layers.value[LayerId.SOURCE].options.visibleFeatures)

const corridorGeoJSON = computed(() => {
  return useCorridorStore().geoJson
})

watch(corridorGeoJSON, updateSource, { immediate: true })
watch(visibleOverheadLines, updateSource)

function updateSource() {
  if (!project.value || !corridorGeoJSON.value) {
    return
  }

  const geoJsonOptions = {
    dataProjection: project.value.crs,
    featureProjection: view.value.getProjection()
  }

  // Find corridors which overhead lines are visible
  const features = new GeoJSON(geoJsonOptions).readFeatures(corridorGeoJSON.value) as Feature[]
  const visibleFeatures = features.filter(
    (f) =>
      visibleOverheadLines.value === undefined || // all selected
      visibleOverheadLines.value?.includes(f.get('overheadLineId'))
  )

  // create or update source
  if (!source.value) {
    source.value = new VectorSource({ features: visibleFeatures })
  } else {
    source.value.clear()
    source.value.addFeatures(visibleFeatures)
  }
}
</script>

<template>
  <LayerVector
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.CORRIDOR"
    :layer-style="corridorStyle"
    :source="source"
  />
</template>
