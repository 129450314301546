<script lang="ts" setup>
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'
import ColorLegend from '@/components/map/layer/result/ColorLegend.vue'
import { emfStyle } from '@/components/map/style/emf'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { Feature } from 'ol'
import { GeoJSON as GeoJSONFormat } from 'ol/format'
import { Vector as VectorSource } from 'ol/source'
import { computed, inject } from 'vue'

const { projectId, project } = useProject()
const calculationStore = useCalculationStore()
calculationStore.ensureLoaded(projectId.value)

const map = inject(UseMapInjectKeys.map)
const { layers } = inject(UseMapInjectKeys.useMap) as UseMapItems

/**
 * Result GeoJSON
 */
const geoJSON = computed(() => {
  if (!calculationStore.resultEmfGeoJSON) {
    return undefined
  }

  const resultGeoJSON = { ...calculationStore.resultEmfGeoJSON }
  resultGeoJSON.features = resultGeoJSON.features.map((f) => ({
    ...f,
    properties: { ...f.properties, _type: 'emf' }
  }))

  return resultGeoJSON
})

/**
 * OpenLayers-Source made of GeoJSON
 */
const source = computed(() => {
  if (!geoJSON.value || !map) {
    return undefined
  }

  const geoJsonOptions = {
    dataProjection: project.value?.crs,
    featureProjection: map.getView().getProjection()
  }

  return new VectorSource({
    features: new GeoJSONFormat(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
  })
})

const showLegend = computed(() => {
  return layers.value['result'].options['showEmfResult']
})
</script>

<template>
  <div>
    <LayerVector
      v-if="source"
      v-bind="$attrs"
      :id="LayerId.EMF"
      :source="source"
      :layer-style="emfStyle"
      :information-on-hover="true"
      render-as-image
    />
    <ColorLegend v-if="showLegend" class="z-30 absolute right-4 top-16 w-[70px]" />
  </div>
</template>
