import { HsbApi } from '@/api'
import { OverheadLineId, ProjectId } from '@/model'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { OverheadLineCorridor, ResponseError } from '@gridside/hsb-api'
import { FeatureCollection } from 'geojson'
import { defineStore } from 'pinia'

export const useCorridorStore = defineStore('corridor', {
  state: () => ({
    corridorsByOverheadLine: {} as Record<OverheadLineId, OverheadLineCorridor>,
    projectId: undefined as undefined | ProjectId,
    pendingCorridors: {} as Record<OverheadLineId, true>,
    loaded: false,
    loading: false
  }),

  getters: {
    geoJson(): FeatureCollection {
      const featureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
      }
      Object.values(this.corridorsByOverheadLine).forEach((item) => {
        // augment the corridor features with the respective overheadLine id
        const features =
          item.corridor?.features.map((feature) => ({
            ...feature,
            properties: {
              ...feature.properties,
              overheadLineId: item.overheadLine
            }
          })) || []
        featureCollection.features.push(...(features as any[]))
      })
      return featureCollection
    }
  },

  actions: {
    clear() {
      this.$reset()
    },

    async loadByOverheadLine(
      projectId: ProjectId,
      overheadLineId: OverheadLineId,
      isRetry = false
    ) {
      const isCorridorPending = !!this.pendingCorridors[overheadLineId]
      // Ignore if we came fron an retry timeout, but the overheadline is not pending anymore
      if (isRetry && !isCorridorPending) {
        return
      }
      // Ignore if there is already a request running
      if (!isRetry && isCorridorPending) {
        return
      }

      // Mark overheadline as pending
      this.pendingCorridors[overheadLineId] = true
      try {
        const geoJson = await HsbApi.overheadLines.getOverheadlineCorridor(
          projectId,
          overheadLineId
        )
        if (geoJson) {
          this.corridorsByOverheadLine[overheadLineId] = geoJson
        }
        delete this.pendingCorridors[overheadLineId]
      } catch (e) {
        if (e instanceof ResponseError && e.response?.status === 409) {
          // corridor can't be loaded because container is not ready yet
          console.log(
            `Corridor for project ${projectId} could not be loaded, because the worker is not ready yet.`
          )
        } else {
          throw e
        }
        setTimeout(() => this.loadByOverheadLine(projectId, overheadLineId, true), 5000)
      }
    },

    async load(projectId: ProjectId) {
      const overheadLines = useOverheadLineStore().items
      const promises: Promise<void>[] = []

      this.loading = true
      try {
        overheadLines.forEach((overheadLine) => {
          promises.push(this.loadByOverheadLine(projectId, overheadLine.overheadLine.id))
        })
        await Promise.all(promises)
      } finally {
        this.loading = false
        this.projectId = projectId
      }
    }
  }
})
