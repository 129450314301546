<script setup lang="ts">
import { LayerId } from '@/components/map'
import { UseMapInjectKeys, UseMapItems, useMapSelection } from '@/components/map/composables'

import LayerBase from '@/components/map/layer/LayerBase.vue'

import { SourceLayerContext } from '@/components/map/layer/util'
import { featureIsDraft, featureOfTypeFn, FeatureTypeEnum } from '@/components/map/LayerItemConfig'

import { sourceStyle } from '@/components/map/style/source'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { Feature } from 'ol'

import { Point } from 'ol/geom'
import { FilterFunction } from 'ol/interaction/Select'
import { Vector as VectorLayer } from 'ol/layer'
import { computed, inject } from 'vue'
import CorridorLayer from './corridor/CorridorLayer.vue'

import { deleteOverheadLines } from './functions'
import { useSourceContextMenu } from './useSourceContextMenu'
import { useSourceGeoJSON } from './useSourceGeoJSON'
import { useSourceHover } from './useSourceHover'
import { useSourceLayerOptions } from './useSourceLayerOptions'
import { useSourceModify } from './useSourceModify'
import { useSourceSelect } from './useSourceSelect'

const { map, layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { selectedFeatures } = useMapSelection()
const { project } = useProject()
const { mapToProject, projectToMap } = useCoordinates(map.value, project.value?.crs)

const layer = new VectorLayer({
  style: sourceStyle,
  properties: { id: LayerId.SOURCE }
})

const store = useOverheadLineStore()

// Build context object that is handed to functions
const ctx: SourceLayerContext = {
  layer,
  map: map.value,
  mapToProject,
  projectToMap,
  selectedFeatures,
  selectedOverheadLines: computed(() =>
    selectedFeatures.value.filter(featureOfTypeFn(FeatureTypeEnum.overheadLine))
  ),
  selectedSpans: computed(() =>
    selectedFeatures.value.filter(featureOfTypeFn(FeatureTypeEnum.span))
  ),
  selectedTowers: computed(
    () => selectedFeatures.value.filter(featureOfTypeFn(FeatureTypeEnum.tower)) as Feature<Point>[]
  ),
  showCorridor: computed(() => layers.value[LayerId.SOURCE].options.showCorridor || false),
  showTowers: computed(() => layers.value[LayerId.SOURCE].options.showTowers || false),
  showSpans: computed(() => layers.value[LayerId.SOURCE].options.showSpans || false),
  visibleFeatures: computed(() => layers.value[LayerId.SOURCE].options.visibleFeatures)
}

const layerIsVisible = computed(() => layers.value[LayerId.SOURCE].visible || false)

// General
useSourceLayerOptions(ctx)
useSourceContextMenu(ctx)

// Interactions
const { selectInteraction } = useSourceSelect(ctx)
ctx.selectInteraction = selectInteraction
useSourceHover(ctx)
useSourceModify(ctx)

// Data source
const { source } = useSourceGeoJSON(ctx)

const showInformationOnHover: FilterFunction = (feat) => {
  return !featureIsDraft(feat)
}
</script>

<template>
  <LayerBase
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.SOURCE"
    :layer="layer"
    :information-on-hover="showInformationOnHover"
    :z-index="11"
    :visible="layerIsVisible"
  />
  <CorridorLayer :visible="layerIsVisible && ctx.showCorridor.value" :z-index="1" />
  <teleport to="body">
    <p-dialog
      confirmLabel="Löschen"
      danger
      :show="store.itemsToDelete.length > 0"
      @update:show="store.itemsToDelete = []"
      @confirm="deleteOverheadLines"
      title="Freileitung löschen"
    >
      <slot name="confirm-delete" :item="store.itemsToDelete" v-if="store.itemsToDelete">
        <template v-if="store.itemsToDelete.length === 1">
          Sind Sie sicher, dass Sie die Freileitung
          <b v-if="store.itemsToDelete[0].name">{{ store.itemsToDelete[0].name }}</b>
          löschen möchten?
        </template>
        <template v-else>
          Sind Sie sicher, dass Sie
          <b>{{ store.itemsToDelete.length }} Freileitungen</b>
          löschen möchten?
        </template>
        <p class="text-gray-500 mt-4">
          Alle zugehörigen Masten und Spannfelder werden ebenfalls gelöscht.
        </p>
      </slot>
    </p-dialog>
  </teleport>
</template>
